<template>

    <div>
        <div class="camera" :class="{playing: videoPlaying, loading: videoLoading}">

            <div class="title">
                <icon icon="video"></icon>
                <span>{{camera.title}}</span>
            </div>


            <div class="wrap" ref="mainElement">

                <div class="aspect"></div>
                <div class="content">
                    <img class='image' :src="imageUrl" alt="">
                    <img class='live' src="../assets/img/live.svg" alt="">

                    <a :href="camera.link" target="_blank"  class="logo">
                        <img :src="camera.logo" alt="">
                    </a>

                    <img class="powered-by" src="../assets/img/powered_by.png" alt="">

                    <div class="loading-element">
                        <div class="background"></div>
                        <spinner class="spinner" :size="80"></spinner>
                        <span>Učitavam...</span>
                    </div>

                    <video class="main" playsinline muted ref="video" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></video>

                    <video class="short visible" playsinline autoplay loop muted ref="videoShort" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                        <source :src="shortVideoUrl" type="video/mp4">
                    </video>

                    <div class="play-video" @click="playVideo">
                        <div class="btn">
                            <icon icon="play"></icon>
                        </div>
                    </div>

                    <div class="full-screen" @click="toggleFullScreen">
                        <div class="btn">
                            <icon icon="maximize-2"></icon>
                        </div>
                    </div>

                    <div class="stop-video" @click="stopVideo">
                        <div class="btn">
                            <icon icon="pause"></icon>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

</template>

<script>

    import Hls from 'hls.js'
    import PinchZoom from 'pinch-zoom-js'
    import settings from "../settings";

    import eventBus from "../eventBus";
    import helpers from "../helpers";

    export default {
        props: {
            camera: {
                required: true,
                type: Object,
            }
        },
        data() {
            return {
                videoLoading: false,
                videoPlaying: false,
                videoPlayingShort: false
            }
        },
        computed: {
            imageUrl() {
                return helpers.urlWithTimestamp(this.camera.image_url)
            },
            shortVideoUrl() {
                return helpers.urlWithTimestamp(this.camera.video_url_short)
            }
        },
        methods: {

            

            /**
             *
             */
            loadVideo() {

                if(helpers.isiOS()){

                    if(this.$refs.videoShort)
                        this.$refs.videoShort.pause();

                    this.$refs.video.src = this.camera.video_url;
                    this.$refs.video.play();

                    this.$refs.video.oncanplaythrough = () => {
                        this.firstFragLoaded = true;
                        this.videoLoading = false;
                        this.videoPlaying = true;
                    }


                }
                else{
    
                    let hlsConfig = this.camera.hls_config ? JSON.parse(this.camera.hls_config) : {};

                    this.hls = new Hls(hlsConfig);

                    this.hls.attachMedia(this.$refs.video);

                    this.firstFragLoaded = false;

                    if(this.$refs.videoShort)
                        this.$refs.videoShort.pause();

                    this.hls.on(Hls.Events.ERROR, function (event, data) {
 
                        if (data.fatal) {
                            switch (data.type) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    this.hls.startLoad();
                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    this.hls.recoverMediaError();
                                    break;
                                default:
                                    this.stopVideo();
                                    break;
                            }
                        }
                    });

                    this.hls.on(Hls.Events.FRAG_PARSED, (event, data) => {

                        if (this.firstFragLoaded)
                            return;

                        this.firstFragLoaded = true
                        this.$refs.video.play();
                        this.videoLoading = false;
                        this.videoPlaying = true;

                    });


                    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {

                        this.hls.loadSource(this.camera.video_url);
                    });
                }


                eventBus.emit("video.started", null, this.camera)

            },

            playVideo() {

                this.videoLoading = true;
                this.loadVideo()

            },


            toggleFullScreen() {

                if ( helpers.isInFullscreen() ){
                    window.document.exitFullscreen();
                }
                else{

                    if(helpers.isiOS()){
                        this.$refs.video.webkitEnterFullscreen();
                    }
                    else{
                        this.$refs.mainElement.requestFullscreen();
                    }
   
                }

            },

            stopVideo() {

                this.videoLoading = false;
                this.videoPlaying = false;

                if(this.$refs.video)
                    this.$refs.video.pause()

                if ( helpers.isInFullscreen() )
                    window.document.exitFullscreen()

                if(this.pz)
                    this.pz.disable();

                if (this.hls){

                    this.hls.detachMedia()
                    this.hls.destroy()

                }

                this.$nextTick(() => {
                    this.$refs.videoShort.play();
                })
                    

                

            },

        },

        mounted() {

            let videoElement = this.$refs.video;
 
            document.addEventListener('fullscreenchange', e => {

                if(!this.pz) return

                if ( helpers.isInFullscreen() ){
                    this.pz.enable();
                }
                else{
                    this.pz.disable();
                    videoElement.style.transform = "";
                }

            });

            eventBus.on("video.started", (camera) => {
                if (this.camera.video_url != camera.video_url) {
                    this.stopVideo()
                }
            })

            document.addEventListener('visibilitychange', () => {
                if (document.hidden)
                    this.stopVideo()
            });

            this.$refs.video.onpause = () => {
                this.stopVideo()
            };

            this.pz = new PinchZoom(videoElement);
            this.pz.disable();

        }
    }


</script>

<style lang="scss" >

    @import "../scss/includes";

    .camera {



        .aspect {
            width: 100%;
            padding-top: 56.25%;
        }

        .wrap {
            position: relative;
            overflow: hidden;
            background: black;
        }

        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        img.image {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

        img.powered-by {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            width: 50%;
            max-width: 300px; 
        }

        img.live{
            transition: all 0.2s ease-in-out;
            transform: translate3d(-10px,0,0);
            width: 8%;
            opacity: 0;
            position: absolute;
            left: 3%;
            top: 5%;
            z-index: 10;
            pointer-events: none;

        }

        .short{
            display: none;
            &.visible{
                display: block;
            }
        }

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            &.main{
                display: none;
                z-index: 5;
            }
        }

        .pinch-zoom-container{
            width: 100%;
            height: 100% !important;
            position: absolute;
        }

        a.logo{
            position: absolute;
            z-index: 10;
            top: 5%;
            right: 3%;
            display: block;
            width: 10%;
            img{
                display: block;
                width: 100%;
            }
        }

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            margin-left: 25px;

            .icon {
                font-size: 15px;
                margin-right: 5px;
            }

            span {
                display: block;
                line-height: 15px;
                font-weight: 500;
                font-size: 0.9em;
            }

        }

        .loading-element {

            padding-right: 10px;
            display: flex;
            align-items: center;
            position: absolute;
            bottom: -5px;
            right: 0px;

            transform: translate3d(10px, 0, 0);
            opacity: 0;
            z-index: 5;

            transition: all 0.2s ease-in-out;


            .background {
                position: absolute;
                bottom: -5px;
                right: 0px;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 5px;
            }

            .spinner {
                transform: scale(0.3);
                z-index: 1;
            }

            span {
                margin-left: -7px;
                margin-top: 1px;
                color: white;
                font-weight: 400;
                font-size: 0.8em;
                line-height: 1;
                z-index: 1;
            }

        }

        &.loading {
            .play-video {
                display: none;
            }

            .loading-element {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
        }

        &.playing {
            video.main {
                display: block;
            }
            video.short {
                display: none;
            }

            img.image{
                display: none;
            }

            img.live{
                opacity: 1;
                transform: translate3d(0,0,0);
            }

            .play-video {
                display: none;
            }

            .stop-video,
            .full-screen {
                display: flex;
            }
        }


        .stop-video,
        .full-screen {
            display: none;
            cursor: pointer;
            position: absolute;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            bottom: 10px;
            font-size: 15px;
            color: white;
            z-index: 10;

            &:hover{
                background: rgba(0, 0, 0, 0.7);
            }

            &:active{
                background: rgba(255, 255, 255, 0.5);
            }

        }

        .stop-video {
            right: 50px;
        }

        .full-screen {
            right: 10px;
        }

        .play-video {
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                width: 40px;
                height: 40px;
                color: white;
                font-size: 19px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparentize(color(1, "red"), 0.2);
                border-radius: 50%;
                cursor: pointer;



                &:hover{
                    background: transparentize(color(1, "red"), 0.1);
                }
            }
        }

    }

</style>














