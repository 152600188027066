import helpers from "../helpers";
import moment from "moment";

export default {

    methods: {

        /**
         *
         * @param string
         * @returns {void|*}
         */
        decodeHTMLEntities(string){
            return helpers.decodeHTMLEntities(string)
        },


        /**
         *
         * @param date
         * @returns {string}
         */
        formatDate(date){
            return moment(date).format('DD.MM.YYYY HH:mm')
        },

        /**
         *
         * @param str
         * @param count
         * @returns {string}
         */
        limitStr(str, count= 40){
            return str.slice(0, count)+'...';
        },



    }


}
